// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as FaIcon from "./FaIcon.bs.js";
import * as Js_math from "../../../../node_modules/rescript/lib/es6/js_math.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_format from "../../../../node_modules/rescript/lib/es6/caml_format.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as ContentBlock from "../types/ContentBlock.bs.js";
import * as MarkdownBlock from "./MarkdownBlock.bs.js";
import * as RescriptReactRouter from "../../../../node_modules/@rescript/react/src/RescriptReactRouter.bs.js";
import * as Webapi__Dom__Element from "../../../../node_modules/rescript-webapi/src/Webapi/Dom/Webapi__Dom__Element.bs.js";
import Youtube from "react-player/youtube";

import "./TargetContentView.css"
;

function str(prim) {
  return prim;
}

function renderBlockClasses(block) {
  var match = ContentBlock.blockType(block);
  switch (match.TAG | 0) {
    case /* Embed */3 :
        return "mt-6 pb-7";
    case /* Markdown */0 :
    case /* File */1 :
    case /* Image */2 :
    case /* Audio */4 :
        return "mt-6";
    
  }
}

function markdownContentBlock(markdown) {
  return React.createElement(MarkdownBlock.make, {
              markdown: markdown,
              profile: /* Permissive */0
            });
}

function fileContentBlock(url, title, filename) {
  return React.createElement("a", {
              "aria-label": "Download " + title,
              className: "flex justify-between bg-white border rounded-lg px-6 py-4 items-center shadow hover:border-gray-500 hover:bg-gray-50 hover:text-primary-500 hover:shadow-md focus:outline-none focus:text-primary-500 focus:ring-2 focus:ring-focusColor-500",
              href: url,
              target: "_blank"
            }, React.createElement("div", {
                  className: "flex items-center"
                }, React.createElement(FaIcon.make, {
                      classes: "text-4xl text-gray-800 far fa-file-alt"
                    }), React.createElement("div", {
                      className: "ps-4 leading-tight"
                    }, React.createElement("div", {
                          className: "text-lg font-semibold"
                        }, title), React.createElement("div", {
                          className: "text-sm italic text-gray-600"
                        }, filename))), React.createElement("div", undefined, React.createElement(FaIcon.make, {
                      classes: "text-2xl fas fa-download"
                    })));
}

function imageContentBlock(url, caption, width) {
  return React.createElement("div", {
              className: "rounded-lg bg-white text-center"
            }, React.createElement("img", {
                  className: "mx-auto w-auto md:" + ContentBlock.widthToClass(width),
                  alt: caption,
                  src: url
                }), React.createElement("div", {
                  className: "px-4 py-2 text-sm italic"
                }, caption));
}

function audioContentBlock(url) {
  return React.createElement("audio", {
              controls: true,
              src: url
            });
}

function setLocalStorage(key, value) {
  localStorage.setItem(key, value);
}

function getLocalStorage(key) {
  return Caml_option.null_to_opt(localStorage.getItem(key));
}

function saveProgress(videoId, currentTime) {
  setLocalStorage("video_progress_" + videoId + "", currentTime.toString());
}

function embedContentBlock(embedCode) {
  var regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(?:embed\/)?(?:v\/)?(?:shorts\/)?(?:\S+(?:si|v|list)=)?([a-zA-Z0-9_-]{11})(?:\S+)?/;
  var result = regex.exec(embedCode);
  var videoId;
  if (result !== null && result.length > 1) {
    var id = result[1];
    videoId = (id == null) ? "" : id;
  } else {
    videoId = "";
  }
  var useIsTargetPath = function (param) {
    var url = RescriptReactRouter.useUrl(undefined, undefined);
    var match = url.path;
    if (!match) {
      return false;
    }
    if (match.hd !== "targets") {
      return false;
    }
    var match$1 = match.tl;
    if (!match$1) {
      return false;
    }
    if (match$1.tl) {
      return false;
    }
    try {
      Caml_format.int_of_string(match$1.hd);
      return true;
    }
    catch (exn){
      return false;
    }
  };
  var isTargetPath = useIsTargetPath(undefined);
  if (!(videoId !== "" && isTargetPath)) {
    return React.createElement("div", {
                className: "learn-content-block__embed",
                dangerouslySetInnerHTML: {
                  __html: embedCode
                }
              });
  }
  var match = React.useState(function () {
        return 0.0;
      });
  var setLastTime = match[1];
  var lastTime = match[0];
  var match$1 = React.useState(function () {
        return 0.0;
      });
  var setDuration = match$1[1];
  var duration = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var set_ = match$2[1];
  var playerRef = React.useRef(null);
  var match$3 = React.useState(function () {
        return false;
      });
  var setIsPlaying = match$3[1];
  var onReady = function (param) {
    var buttons = Array.prototype.slice.call(document.querySelectorAll(".curriculum-overlay__learn-submit-btn"));
    Belt_Array.forEach(buttons, (function (button) {
            var htmlButton = Webapi__Dom__Element.asHtmlElement(button);
            if (htmlButton !== undefined) {
              Caml_option.valFromOption(htmlButton).setAttribute("disabled", "true");
              return ;
            }
            
          }));
    console.log([
          "YouTubeプレーヤーの準備が完了しました",
          playerRef.current
        ]);
    var savedProgress = localStorage.getItem("video_progress_" + videoId + "");
    if (savedProgress === null) {
      return ;
    }
    var time = Number(savedProgress === null ? undefined : Caml_option.some(savedProgress));
    var player = playerRef.current;
    if (!(player == null)) {
      player.seekTo(time, "seconds");
      return ;
    }
    
  };
  var onEnd = function (param) {
    console.log("動画が終了しました");
    var buttons = Array.prototype.slice.call(document.querySelectorAll(".curriculum-overlay__learn-submit-btn"));
    Belt_Array.forEach(buttons, (function (button) {
            var htmlButton = Webapi__Dom__Element.asHtmlElement(button);
            if (htmlButton !== undefined) {
              Caml_option.valFromOption(htmlButton).removeAttribute("disabled");
              return ;
            }
            
          }));
  };
  var onError = function (param) {
    console.log("エラーが発生しました");
  };
  return React.createElement(Youtube, {
              url: "https://www.youtube.com/watch?v=" + videoId,
              playing: match$3[0],
              controls: true,
              className: "learn-content-block__embed",
              width: "100%",
              height: "100%",
              progressInterval: 100,
              config: {
                youtube: {
                  playerVars: {
                    origin: window.location.origin
                  }
                }
              },
              onReady: onReady,
              onPlay: (function (param) {
                  console.log("再生開始");
                  Curry._1(setIsPlaying, (function (param) {
                          return true;
                        }));
                }),
              onPause: (function (param) {
                  console.log("一時停止");
                  Curry._1(setIsPlaying, (function (param) {
                          return false;
                        }));
                }),
              onBuffer: (function (param) {
                  console.log("バッファリング中");
                  Curry._1(set_, (function (param) {
                          return true;
                        }));
                }),
              onBufferEnd: (function (param) {
                  console.log("バッファリング終了");
                  Curry._1(set_, (function (param) {
                          return false;
                        }));
                }),
              onError: onError,
              onEnded: onEnd,
              onProgress: (function (state) {
                  var state$1 = {
                    played: state.played,
                    playedSeconds: state.playedSeconds,
                    loaded: state.loaded,
                    loadedSeconds: state.loadedSeconds
                  };
                  var player = playerRef.current;
                  if (player == null) {
                    console.log("プレーヤーが初期化されていません");
                    return ;
                  }
                  var currentTime = state$1.playedSeconds;
                  if (duration === 0.0) {
                    Curry._1(setDuration, (function (param) {
                            return player.getDuration();
                          }));
                  }
                  var timeDiff = currentTime - lastTime;
                  var isSkip = timeDiff > 3.0 && currentTime < duration - 1.0;
                  if (isSkip) {
                    console.log([
                          "スキップを検出しました！",
                          state$1
                        ]);
                    player.seekTo(lastTime, "seconds");
                    console.log("スキップは許可されていません。");
                    ((alert('スキップは許可されていません。')));
                  } else {
                    Curry._1(setLastTime, (function (param) {
                            return currentTime;
                          }));
                    if (Js_math.floor(currentTime) % 10 === 0) {
                      saveProgress(videoId, currentTime);
                    }
                    
                  }
                  console.log("Current time:", currentTime);
                }),
              ref: playerRef
            });
}

function TargetContentView$EmbedBlock(Props) {
  return embedContentBlock(Props.embedCode);
}

var EmbedBlock = {
  make: TargetContentView$EmbedBlock
};

function TargetContentView(Props) {
  var contentBlocks = Props.contentBlocks;
  return React.createElement("div", {
              className: "text-base",
              id: "learn-component"
            }, $$Array.map((function (block) {
                    var markdown = ContentBlock.blockType(block);
                    var renderedBlock;
                    switch (markdown.TAG | 0) {
                      case /* Markdown */0 :
                          renderedBlock = markdownContentBlock(markdown._0);
                          break;
                      case /* File */1 :
                          renderedBlock = fileContentBlock(markdown._0, markdown._1, markdown._2);
                          break;
                      case /* Image */2 :
                          renderedBlock = imageContentBlock(markdown._0, markdown._1, markdown._2);
                          break;
                      case /* Embed */3 :
                          renderedBlock = Belt_Option.mapWithDefault(markdown._1, null, embedContentBlock);
                          break;
                      case /* Audio */4 :
                          renderedBlock = audioContentBlock(markdown._0);
                          break;
                      
                    }
                    return React.createElement("div", {
                                key: ContentBlock.id(block),
                                className: renderBlockClasses(block)
                              }, renderedBlock);
                  }), ContentBlock.sort(contentBlocks)));
}

var make = TargetContentView;

export {
  str ,
  renderBlockClasses ,
  markdownContentBlock ,
  fileContentBlock ,
  imageContentBlock ,
  audioContentBlock ,
  setLocalStorage ,
  getLocalStorage ,
  saveProgress ,
  embedContentBlock ,
  EmbedBlock ,
  make ,
}
/*  Not a pure module */
