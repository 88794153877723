// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "./I18n.bs.js";

var partial_arg = "shared.level_label";

function ts(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function format(shortOpt, name, number) {
  var $$short = shortOpt !== undefined ? shortOpt : false;
  if (name !== undefined) {
    if ($$short) {
      return ts([
                  [
                    "number",
                    number
                  ],
                  [
                    "name",
                    name
                  ]
                ], undefined, "short_with_name");
    } else {
      return ts([
                  [
                    "number",
                    number
                  ],
                  [
                    "name",
                    name
                  ]
                ], undefined, "long_with_name");
    }
  } else if ($$short) {
    return ts([[
                  "number",
                  number
                ]], undefined, "short_without_name");
  } else {
    return ts([[
                  "number",
                  number
                ]], undefined, "long_without_name");
  }
}

function searchString(number, name) {
  return ts([
              [
                "number",
                number
              ],
              [
                "name",
                name
              ]
            ], undefined, "long_with_name");
}

export {
  ts ,
  format ,
  searchString ,
}
/* I18n Not a pure module */
